.file_input {
    user-select: none;
    pointer-events: auto;
    font-kerning: none;
    --border-radius: 4px;
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 1.1em;
    --padding-end: 1.1em;
    --transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), background-color 15ms linear, color 15ms linear;
    --background: var(--ion-color-primary, #3880ff);
    --color: var(--ion-color-primary-contrast, #fff);
    --background-activated: transparent;
    --background-hover: var(--ion-color-primary-contrast, #fff);
    --background-focused: var(--ion-color-primary-contrast, #fff);
    --background-activated-opacity: 0;
    --background-focused-opacity: 0.24;
    --background-hover-opacity: 0.08;
    --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    -webkit-tap-highlight-color: transparent;
    visibility: inherit;
    border-radius: var(--border-radius);
    -webkit-font-smoothing: antialiased;
    margin: 0px;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    text-decoration: inherit;
    text-indent: inherit;
    text-overflow: inherit;
    text-transform: inherit;
    text-align: inherit;
    white-space: inherit;
    color: inherit;
    display: block;
    position: relative;
    transition: var(--transition);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    outline: none;
    background: var(--background);
    line-height: 1;
    box-shadow: var(--box-shadow);
    contain: layout style;
    cursor: pointer;
    opacity: var(--opacity);
    overflow: var(--overflow);
    z-index: 0;
    box-sizing: border-box;
    appearance: none;
    padding-left: unset;
    padding-right: unset;
    padding-inline-start: var(--padding-start);
    padding-inline-end: var(--padding-end);
    padding: 12px 16px;
    max-width: 150px;
    text-align: center;
    color: white;
    display: flex;
    justify-content: space-between;
    margin: 2px;
}