ion-menu ion-content {
  --padding-top: 20px;
  --padding-bottom: 20px;

  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

/* Remove background transitions for switching themes */
ion-menu ion-item {
  --transition: none;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

/*
 * Material Design Menu
*/
ion-menu.md ion-list {
  padding: 20px 0px;
}

ion-menu.md ion-list-header {
  padding-left: 18px;
  padding-right: 18px;

  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 450;
}

ion-menu.md ion-item {
  --padding-end: 18px;

  margin-left: 10px;

  border-radius: 50px 0 0 50px;

  font-weight: 500;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-list-header,
ion-menu.md ion-item ion-icon {
  color: var(--ion-color-step-650, #5f6368);
}

ion-menu.md ion-list:not(:last-of-type) {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}


/*
 * iOS Menu
*/
ion-menu.ios ion-list-header {
  padding-left: 16px;
  padding-right: 16px;

  margin-bottom: 8px;
}

ion-menu.ios ion-list {
  padding:  0 20px 20px;
}

ion-menu.ios ion-item {
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}
